import { Fragment, useState, useEffect} from 'react';
import "./App.css";
import StripeEmbedded from "./StripeEmbedded.jsx"
import CountdownClock from './Countdown.jsx';
import clockoutline from "./assets/clockoutline.svg";
import { QRCode } from 'react-qrcode-logo';
import Claimed from './Claimed.jsx';
import Empty from './Empty.jsx'

const venue = 'TAO2' //set venue here
const venueLocation = "Brooklyn NYC"



function App() {

  const [currentPage, setCurrentPage] = useState(0)
  const [approved, setApproved] = useState(false)
  

  const handleStripeStatusChange = (status) => {
    status === 'complete' ? changePage(5) : changePage(6)
  };

  const handleApprovedStatus = (approvedStatus) => {
    setApproved(approvedStatus)
    console.log('APPROVEDSTATUS= ' + approved)
    approved ? changePage(6) : changePage(7)
  }

  const changePage = (page) => {
    setCurrentPage(page)
  }

  const bottomSection = {
    0: <Intro changePage={changePage} />,
    1: <ServiceProposal changePage={changePage}/>,
    2: <HowItWorks changePage={changePage}/>,
    3: <ServiceRequested changePage={changePage}/>,
    4: <StripeEmbedded onStatusChange={handleStripeStatusChange} />,
    5: <ApprovalPending  onStatusChange={handleApprovedStatus}/>,
    6: <PaymentFailed  changePage={changePage}/>,
    7: <RequestApproved changePage={changePage}/>,
    8: <ClaimedState/>,
    9: <EmptyState />
  }
  

  return (
      <div className="bg-lightColor min-h-screen relative flex flex-col">
          <div className="flex-grow">
            {bottomSection[currentPage]}
          </div>
        <section id="footer" className="pb-6">
          <p className="font-montserrat mt-12 text-[12px] text-baseColor uppercase text-center tracking-widest">
            Concierge by Romp
          </p>
          <p className="text-center mt-0 text-wrap font-montserrat text-[12px] text-black">
               hello@rompconcierge.com
          </p>
          
        </section>
      </div>
  );
  }

  function ClaimedState() {
    return (
      <Fragment>
        < Top/>
        <Claimed/>
      </Fragment>
      )
  }

  function EmptyState() {
    return (
      <Fragment>
        <Empty/>
      </Fragment>
    )
  }


  function Top() {

    return (
      <>
        {/*  <div className="relative bg-darkColor py-4 flex justify-center">
            <img className="relative w-40 left-4" src={svg} alt="" />
          </div>
        */}
        
          <div className="py-7 border-b-2 border-darkColor ">
            <p className="font-bookmania tracking-widest text-[17px] text-baseColor uppercase text-center">
              Skip The Line At
            </p>
            <p className="font-bookmaniaBold tracking-widest text-[36px] text-baseColor uppercase text-center leading-none mt-2">
              {venue}
            </p>
            <p className="font-montserrat text-[14px] text-baseColor uppercase text-center tracking-widest">
              {venueLocation}
            </p>
          </div>

      </>
    );
  }

//0
  function Intro( {changePage} ) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const qrClaim = urlParams.get('claim')
    const qrVenue = urlParams.get('venue')


    //the continue button calls this explicitly,
    const handleContinue = () => {
      sessionId === null ?  changePage(1) : changePage(4)
    }

    //runs immediately on component load.     
    if ( sessionId !== null) { 
      handleContinue() 
    } else {
      if ( qrClaim !== null ) { 
          changePage(8) 
        } else {
          if ( qrVenue === venue ) { 
              changePage(0) } 
            else { 
              changePage(9) 
            }
      }
    }

    
    return (
      <>
        <div className="py-20">
          <p className="font-bookmania tracking-widest text-[17px] text-baseColor uppercase text-center">
            Skip The Line At
          </p>
          <p className="font-bookmaniaBold tracking-widest text-[36px] text-baseColor uppercase text-center leading-none mt-2">
          {venue}
          </p>
          <p className="font-montserrat text-[14px] text-baseColor uppercase text-center tracking-widest">
            { venueLocation }
          </p>
        </div>

          <div id='continue1' className="flex justify-center">
          <button className="w-fit mb-4 px-10 py-2 mx-auto bg-baseColor hover:bg-hoverAccent text-white font-montserrat text-[14px] font-bold rounded-xl "
              onClick = { handleContinue } >
            Continue
          </button>
          </div>
 
     </>
    );
  }

//1
  function  ServiceProposal( { changePage }) {

    const handleContinue = () => {
      changePage(2)
    }

    const handleCancel = () => {
      changePage(0)
    }

    return (
      <Fragment>
      < Top/>
      <div>
        <div className="w-fit my-8 px-10 py-2 mx-auto bg font-montserrat text-[14px] font-bold rounded-md "
              onClick = {handleContinue} >
          Available Now
        </div>
        <div className="flex justify-center" >
          <div className="bg-darkColor max-w-sm mx-4 text-wrap px-4 py-4 mb-8 rounded-xl font-montserrat text-[14px] text-black">
            <p className="text-left">
              Concierge Service is available to help you skip the line and enter
              this venue right away.
              <br />
              <br />
              Availability is limited. Request to find out if you can skip the line.
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="flex flex-col space-y-4">
          <button onClick={ handleContinue }  className="w-50 px-6 py-2  bg-baseColor hover:bg-hoverAccent font-montserrat text-[14px] text-white rounded-xl ">
            Continue
          </button>
          <button onClick={ handleCancel } className="w-50 px-8 py-2  bg-darkColor hover:bg-lightColor font-montserrat text-[14px] text-black rounded-xl ">
            No, thank you
          </button>
          </div>
        </div>
      </div>
      </Fragment>
    )
  }

//2
  function  HowItWorks( { changePage }) {

    const handleContinue = () => {
      changePage(3)
    }

    const handleCancel = () => {
      changePage(0)
    }

    return (
      <Fragment>
      < Top/>
      <div>
        <div  className = "flex justify-center" >
          <div className="text-wrap max-w-sm m-5 font-montserrat text-[14px] text-baseColor text-center font-semibold">
              How Skip The Line Works
          </div>
        </div>

        <div className="flex justify-center" >
          <div className="bg-baseColor max-w-sm text-wrap px-3 py-1 rounded-md font-montserrat font-semibold text-[12px] text-white">
              1. REQUEST
          </div>
        </div>
        <div  className = "flex justify-center mb-8" >
          <div className="text-wrap max-w-sm mx-4 mt-1 font-montserrat text-[14px] text-black text-center">
            The Concierge attendant  will receive your request to skip the line. 
          </div>
        </div>

        <div className="flex justify-center" >
          <div className="bg-baseOrange max-w-sm text-wrap px-3 py-1 rounded-md font-montserrat font-semibold text-[12px] text-white">
              2. GET APPROVED
          </div>
        </div>
        <div  className = "flex justify-center mb-8" >
          <div className="text-wrap max-w-sm mx-6 mt-1 font-montserrat text-[14px] text-black text-center">
          Event security will verify whether its safe and non-disruptive to admit you. If approved, you will receive a pass code to enter the venue right away.
          </div>
        </div>

        <div className="flex justify-center" >
          <div className="bg-baseGreen max-w-sm text-wrap px-3 py-1 rounded-md font-montserrat font-semibold text-[12px] text-white">
              3. WALK RIGHT IN
          </div>
        </div>
        <div  className = "flex justify-center mb-8" >
          <div className="text-wrap max-w-sm mx-6 mt-1 font-montserrat text-[14px] text-black text-center">
          Present your passcode to security, pay the concierge fee and walk right in.
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="flex flex-col space-y-4">
          <button onClick={ handleContinue }  className="w-50 px-6 py-2  bg-baseColor hover:bg-hoverAccent font-montserrat text-[14px] text-white rounded-xl ">
            Request to skip the line
          </button>
          <button onClick={ handleCancel } className="w-50 px-8 py-2  bg-darkColor hover:bg-lightColor font-montserrat text-[14px] text-black rounded-xl ">
            No, thank you
          </button>
          </div>
        </div>
      </div>
      </Fragment>
    )
  }

//3
  function  ServiceRequested({ changePage }) {

    const handleContinue = () => {
      changePage(4)   //CHANGE to 4 to include StripeCheckOUT, 5 to exclude
    }

    const handleCancel = () => {
      changePage(0)
    }

    return (
    <Fragment>
      <Top/>
      <div>
        <div className="flex justify-center py-6" >

          <div className="flex ml-10 items-center justify-center w-18 h-18 rounded-full bg-baseColor"> 
            <span className="text-white text-center font-montserrat text-[22px] font-bold p-3">$40</span>
          </div>
          
          <div  className = "max-w-xs text-left mx-2 mb-2" >
            <div className="mx-1 text-wrap font-montserrat text-[16px] text-baseColor font-bold">
                Concierge Fee.
            </div>

            <div className="mx-1 text-wrap font-montserrat text-[12px] text-baseColor">
                Does not include general entrance fee
            </div>
          </div>
        </div>

        <div className="flex justify-center" >
          <div className="bg-darkColor max-w-sm mx-4 text-wrap px-4 py-4 mb-8 rounded-xl font-montserrat text-[14px] text-black">
            <p className="text-left ">
            Enter a payment method to complete the service request.
              <br />
              <br />
            If accepted your card will be charged and you’ll be invited to skip the line and enter this venue right away. 
            </p>
          </div>
        </div>

        
        <div className="flex justify-center items-center">
        <div className="flex flex-col space-y-4">
            <button onClick={ handleContinue }  className="w-70 px-6 py-2 mx-auto bg-baseColor hover:bg-hoverAccent font-montserrat text-[14px] text-white rounded-xl flex-grow ">
              Enter a payment method
            </button>

            <button onClick={ handleCancel } className="w-60 my-4 px-16 py-2 mx-auto bg-darkColor hover:bg-lightColor font-montserrat text-[14px] text-black rounded-xl flex-grow">
              Go back
            </button>
          </div>
        </div>

        </div>
  
      </Fragment>
    )
  }

//5
  function  ApprovalPending( {onStatusChange }) {

    return (
      <Fragment>
      < Top/>
      <div>
        <div className="flex items-center justify-center w-fit my-8 px-4 py-2 mx-auto bg-hoverAccent font-montserrat text-[16px] text-white rounded-md">
              <img className="w-6 h-6 mr-2 left-4" src={clockoutline} alt="" />
            Request Pending
        </div>
        <div className="flex justify-center" >
          <div className="bg-darkColor max-w-sm mx-4 text-wrap px-4 py-4 mb-8 rounded-xl font-montserrat text-[14px] text-black">
            <p className="text-left">
              Your request to skip the line is pending approval. If approved you will have 2 minutes to present your passcode for entry. So be ready!

            </p>
          </div>
        </div>
        <div  className = "flex justify-center" >
          <div className="text-wrap max-w-sm m-5 font-montserrat text-[14px] text-black text-center">
              Please wait. Do not approach the entrance until this request is approved
          </div>
        </div>

        <div className="w-fit my-2 px-6 py-2 mx-auto font-montserrat text-[22px] font-smibold text-hoverAccent rounded-xl ">
          < CountdownClock onStatusChange = { onStatusChange } />
        </div>
      </div>
      </Fragment>
    )
  }

//6
  function  PaymentFailed( {changePage}) {
    return (
      <Fragment>
      < Top/>
      <div>

        <div className="flex justify-center" >
          <div className="bg-darkColor max-w-sm mx-4 my-10 text-wrap px-4 py-4 mb-8 rounded-xl font-montserrat text-[14px] text-black">
            <p className="text-left">
              PAYMENT FAILED
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="flex flex-col space-y-4">
          <button onClick={ changePage(4) }  className="w-50 px-6 py-2  bg-baseColor hover:bg-hoverAccent font-montserrat text-[14px] text-white rounded-xl ">
            Try Again
          </button>
          <button onClick={ changePage(0) } className="w-50 px-8 py-2  bg-darkColor hover:bg-lightColor font-montserrat text-[14px] text-black rounded-xl ">
            Exit
          </button>
          </div>
        </div>
      </div>
      </Fragment>
    )
  }


//7
  function  RequestApproved( { changePage }) {   // { changePage }
    const theSeconds = 3 * 60 // time in seconds
    const [timeLeft, setTimeLeft] = useState(theSeconds); 
    
    const currentUrl = window.location.href

    const handleContinue= () => {
      changePage(6)
    }


    useEffect(() => {
      if (timeLeft > 0) {
        const timer = setInterval(() => {
          setTimeLeft(prevTime => prevTime - 1);
        }, 1000);
  
        // Cleanup interval on component unmount
        return () => clearInterval(timer);
      } 
    }, [timeLeft]);
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
      <Fragment>
      < Top/> 
      <div>

        <div className="flex flex-col items-center" >
        <div className ="text-wrap font-montserrat text-[14px] text-black text-center mt-5 mx-5">
                Welcome to {venue}! Present this passcode to the Concierge attendant for immediate entry
              </div>
          <div className="flex justify-center bg-white w-60 h-80 bg-white shadow-md mx-4 my-3 rounded-xl px-4 py-4 ">
              {timeLeft === 0 && (
              <div className="absolute inset-0 flex justify-center items-center">
                <div className="text-black bg-white  px-3 rounded-lg text-4xl font-bold transform rotate-45">
                  EXPIRED
                </div>
              </div>) }
            <div className="flex flex-col items-center">
              <QRCode value= { currentUrl.replace('return?session_id', '?claim') } qrStyle="dots" eyeRadius={5} size={175} 
                      eyeColor={[
                          { // top/left eye
                            outer: 'black',
                            inner: 'black',
                          },
                          { // top/right eye
                            outer: 'black',
                            inner: 'black',
                          },
                          { // bottom/left eye
                            outer: 'black',
                            inner: 'black',
                          },
                        ]}/>
              
              
              { timeLeft > 0 ? 
                <div className ="text-wrap font-montserrat text-[14px] text-black text-center mt-5">
                    VIP Entry <br/>  Passcode Expires in {formatTime(timeLeft)} 
                </div> :
                <div className ="text-wrap font-montserrat text-[12px] text-black text-center mt-3 mb-5">
                  Sorry, this passcode has expired <br/>
                  Your card has not been charged. 
                  <div className="flex justify-center items-center mt-3 mb-3 ">
                    <div className="flex flex-col space-y-4">
                        <button onClick={ handleContinue }  className="tm=4 w-70 px-6 py-2 mx-auto bg-baseColor hover:bg-hoverAccent font-montserrat text-[14px] text-white rounded-xl flex-grow ">
                          Refresh Entry Code
                        </button>
                      </div>
                    </div>
                </div>
              }
            </div>
          </div>
        </div>


    </div> 
      </Fragment>
    )
  }










export default App;
