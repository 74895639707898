import { useState, useEffect } from 'react';

const CountdownClock = ({ onStatusChange }) => {
    const [timeRemaining, setTimeRemaining] = useState(1 * 7 * 1000);
    const [timerFinished, setTimerFinished] = useState(false);
    const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
    const seconds = Math.floor((timeRemaining / 1000) % 60);
  

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeRemaining(prevTime => {
          if (prevTime <= 0) {
            clearInterval(interval);
            setTimerFinished(true)
            //pass approval state to parent component
            onStatusChange(true)
            return 0;
          }
          return prevTime - 10;
        });
      }, 10);
  
      return () => clearInterval(interval);
    }, [ onStatusChange ]);

    if(!timerFinished ) {
      return (
        <p>
          {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
        </p> )
        
    }
  
  };  

export default CountdownClock;